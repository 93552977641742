import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useAuth } from '../api/services/auth';
import { HalfCircleSpinner } from 'react-epic-spinners';

const OtpForm = () => {
    const [otp, setOtp] = useState(null);
    const [errors, setErrors] = useState({});
    const { verifyOtp, showLoader } = useAuth();

    // Validate OTP input
    const validateOtp = () => {
        let errors = {};
        let isValid = true;
        if (!otp) {
            isValid = false;
            errors['otp'] = 'Please enter the OTP sent to your email.';
            toast.error('Please enter the OTP sent to your email.');
        }
        setErrors(errors);
        return isValid;
    };

    // Handle OTP verification
    const otpHandler = (event) => {
        event.preventDefault();

        if (validateOtp()) {
            const { username, password } = window.history.state;

            if (username && password && otp) {
                verifyOtp(username, password, Number(otp));
            }
        }
    };

    return (
        <form className="loginForm otpForm" onSubmit={otpHandler}>
            <div className="formRow">
                <div className="inputWrap">
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span style={{margin: '0 5px'}}>-</span>}
                        renderInput={(props) => <input {...props} type='number' className='formControl textCenter otpField' style={{padding: '15px 5px'}} />}
                    />
                </div>
            </div>
            <div className="formBtnRow textCenter fontSmall">
                <button
                    type="submit"
                    className="btn btnPrimary dBlock w100"
                    disabled={showLoader}
                >Verify OTP
                    {showLoader && (
                        <HalfCircleSpinner
                            size={18}
                            style={{
                                display: "inline-block",
                                marginLeft: "7px",
                                position: 'relative',
                                top: '2px',
                            }}
                        />
                    )}
                </button>
            </div>
        </form>
    );
}

export default OtpForm;