import React, { useState } from 'react';
import { useAuth } from '../../api/services/auth';
import Logo from "../../assets/images/logo-colored.svg";
import LoginForm from '../../components/loginForm';
import { Navigate } from 'react-router-dom';
import OtpForm from '../../components/otpVerificationForm';

const Login = (props) => {
    const { userExist, otpSent, showLoader } = useAuth();
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
    const [credentials, setCredentials] = useState({ username: '', password: '' });

    const handleLoginSuccess = (username, password) => {
        setCredentials({ username, password });
    };
    
    if (userExist) {
        return <Navigate to="/dashboard" />
    }

    return (
        <section className="loginSection">
            <div className="contentWrap">
                <div className="sectHeader textCenter fontSmall">
                    <div className="logoWrap">
                        <img src={Logo} className="imgFluid" alt="NOHC" />
                    </div>
                    <div className='detailTxt'>
                        {showForgotPasswordForm ? (
                            <p>Enter your email to reset your password.</p>
                        ) : otpSent ? (
                            <p>Enter the OTP sent to your email.</p>
                        ) : (
                            <p>Enter your username and password to access your account.</p>
                        )}
                    </div>
                </div>
                {otpSent ? (
                    <OtpForm
                        credentials={credentials} 
                    />
                ) : (
                    <LoginForm
                        showForgotPasswordForm={showForgotPasswordForm}
                        setShowForgotPasswordForm={setShowForgotPasswordForm}
                        onLoginSuccess={handleLoginSuccess}
                    />
                )}
            </div>
        </section>
    );
}

export default Login;